
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class LoginHelper extends Vue {
    accounts: any = [];

    property = "";

    mounted() {
        const sampleUser1 = JSON.parse((process.env.VUE_APP_SAMPLE_USER1 as string) || "{}");
        const sampleUser2 = JSON.parse((process.env.VUE_APP_SAMPLE_USER2 as string) || "{}");
        const sampleUser3 = JSON.parse((process.env.VUE_APP_SAMPLE_USER3 as string) || "{}");
        const sampleUser4 = JSON.parse((process.env.VUE_APP_SAMPLE_USER4 as string) || "{}");
        const sampleUser5 = JSON.parse((process.env.VUE_APP_SAMPLE_USER5 as string) || "{}");
        const sampleUser6 = JSON.parse((process.env.VUE_APP_SAMPLE_USER6 as string) || "{}");
        const sampleUser7 = JSON.parse((process.env.VUE_APP_SAMPLE_USER7 as string) || "{}");
        if (window.innerWidth >= 480) {
            this.property = "marginTop";
        }

        this.accounts = [
		!!Object.keys(sampleUser1).length ? { ...sampleUser1, show: true } : undefined, 
		!!Object.keys(sampleUser2).length ? { ...sampleUser2, show: true } : undefined, 
		!!Object.keys(sampleUser3).length ? { ...sampleUser3, show: true } : undefined,
		!!Object.keys(sampleUser4).length ? { ...sampleUser4, show: true } : undefined,
		!!Object.keys(sampleUser5).length ? { ...sampleUser5, show: true } : undefined,
		!!Object.keys(sampleUser6).length ? { ...sampleUser6, show: true } : undefined,
		!!Object.keys(sampleUser7).length ? { ...sampleUser7, show: true } : undefined,
	].filter((f) => !!f);

        const getStyle = (index: number) => {
            if (window.innerWidth >= 480) {
                return { marginTop: `${index * 70}px` };
            } else {
                return { marginBottom: `${index * 70}px` };
            }
        };
    }

    login(account: { email: string; password: string }) {
        const email: HTMLElement | null = document.querySelector("[type=email]");
        const password: HTMLElement | null = document.querySelector("[type=password]");
        const submit: HTMLElement | null = document.querySelector("[type=submit]");

        if (email && password && submit) {
            // @ts-ignore
            email.value = account.email;

            // @ts-ignore
            password.value = account.password;

            this.$nextTick(function () {
                email.dispatchEvent(new Event("focus"));
                email.dispatchEvent(new Event("input"));
                email.dispatchEvent(new Event("blur"));
                password.dispatchEvent(new Event("focus"));
                password.dispatchEvent(new Event("input"));
                password.dispatchEvent(new Event("blur"));
                this.$nextTick(function () {
                    submit?.click();
                });
            });
        }
    }

    getStyle(index: number) {
        if (window.innerWidth >= 480) {
            return { marginTop: `${index * 70}px` };
        } else {
            return { marginBottom: `${index * 70}px` };
        }
    }
}
