
import { Action } from "vuex-class";
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";
import { PublicOffice } from "@/store/modules/public-office.store";
import LoginHelper from "@/components/_dev/login-helpers.vue";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormSubmit,
        LoginHelper,
    },
})
export default class PageLogin extends Vue {
    @Action("auth/login") login!: (payload: LoginPayload) => Promise<void>;

    @Ref() form!: FormClass;

    @Ref() emailinput!: FormInput;

    payload: LoginPayload = {
        email: "",
        password: "",
    };

    passwordVisible: boolean = false;

    step: number = 1;

    currentOffice: null | PublicOffice = null;

    created() {
        this.currentOffice = Vue.$cookies.get("hubr-office");
        if (this.currentOffice && this.currentOffice.theme.colors.primary) {
            document.documentElement.style.setProperty("--office-500", this.currentOffice.theme.colors.primary);
        }
    }

    mounted(): void {
        if (this.$route && this.$route.query && this.$route.query.email) {
            this.payload.email = this.$route.query.email as string;
        }
    }

    private submit(form: FormClass) {
        this.login(this.payload)
            .then(() => this.$router.push({ name: "home" }))
            .catch((errorResponse: ErrorResponse) => {
                this.$errorResponse = errorResponse;
                form.reset();
            });
    }

    @Watch("step")
    handleStepChanged() {
        if (this.step === 2) {
            this.emailinput.input.focus();
        }
    }
}
